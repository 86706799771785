import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { DefaultDialog } from "@/components/dialog";
import { fetchRequestDemo } from "@/components/home/api";
import { Button } from "@/components/ui/button";
import { Form, FormItemField, FormItemTextArea } from "@/components/ui/form";

const formSchema = z.object({
  full_name: z.string().min(1, "Full name is required"),
  email: z.string().min(1, "Full name is required").email(),
  message: z.string().optional(),
});

export default function RequestDemoForm() {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      full_name: "",
      email: "",
      message: "",
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      await fetchRequestDemo(values);
      setSubmitSuccess(true);
    } catch (err: any) {
      setSubmitError(err.message);
    }
  }

  return (
    <>
      {submitError && <div className="mb-3 text-red-500">{submitError}</div>}
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="space-y-4 md:space-y-6"
        >
          <FormItemField
            control={form.control}
            label="Full Name"
            name="full_name"
            placeholder="Enter full name"
            inputProps={{
              className: "h-12 !placeholder:p3 text-coffee-500",
            }}
            labelClass="text-coffee-50 lg:p2-medium-mobile"
          />
          <FormItemField
            control={form.control}
            label="Email Address"
            name="email"
            placeholder="Enter email address"
            inputProps={{
              className: "h-12 !placeholder:p3 text-coffee-500",
            }}
            labelClass="text-coffee-50 lg:p2-medium-mobile"
          />
          <FormItemTextArea
            control={form.control}
            label="Message (Optional)"
            name="message"
            placeholder="How can we help you?"
            labelClass="text-coffee-50 lg:p2-medium-mobile"
            inputProps={{
              className: "!min-h-[97px] !placeholder:p3 text-coffee-500",
            }}
          />

          <div className="pt-8 lg:pt-10">
            <Button variant="gold" className="w-full" type="submit">
              Request a Demo
            </Button>
          </div>
        </form>
      </Form>
      {submitSuccess && <SuccessDialog onEsc={() => setSubmitSuccess(false)} />}
    </>
  );
}

function SuccessDialog({ onEsc }: any) {
  return (
    <DefaultDialog title="" onEsc={onEsc}>
      <div className="pb-6 text-center">
        <div className="subtitle-1 text-center text-black">
          Thank you for getting in touch!
        </div>
        <div className="p2 mt-2 text-black">
          Thank you for your interest! Your demo request has been successfully
          submitted. Our team will review your information and get in touch with
          you shortly to schedule your demo. If you have any questions in the
          meantime, please feel free to contact us. We look forward to showing
          you what we can do!
        </div>
      </div>
      <div className="py-6">
        <Button className="w-full" onClick={onEsc}>
          Ok
        </Button>
      </div>
    </DefaultDialog>
  );
}
