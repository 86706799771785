import Link from "next/link";

import RequestDemoForm from "@/components/home/request-demo-form";

export function Footer() {
  return (
    <div className="pb-12">
      <div className="bg-interface-black p-4 px-4 py-10 lg:py-20 xl:px-0">
        <div className="m-auto flex max-w-screen-xl flex-col gap-10 lg:flex-row lg:items-center lg:gap-10 xl:gap-32">
          <div className="lg:w-1/2">
            <div className="h2 text-2xl text-interface-white lg:text-[44px] lg:leading-[55.44px]">
              Interested in Learning More?{" "}
            </div>
            <div className="md:p1 mt-2 text-coffee-100">
              We&apos;d love to hear from you.
            </div>
            <div className="mt-8" id="request_demo">
              <RequestDemoForm />
            </div>
          </div>

          <div className="lg:w-1/2">
            <div className="lg:p4 divide-y divide-gold-100 rounded-md border border-gold-100 p-4 text-xs text-coffee-200 lg:p-10">
              <div className="flex justify-between gap-8 pb-[22px]">
                <span className="text-coffee-200">Phone</span>
                <span className="lg:p3 text-interface-white">866-954-5227</span>
              </div>
              <div className="flex justify-between gap-8 pb-[22px] pt-5">
                <span className="text-coffee-200">Email Address</span>
                <span className="lg:p3 text-interface-white">
                  support@secondlab.com
                </span>
              </div>
              <div className="flex justify-between gap-20 pb-3 pt-5">
                <span className="text-coffee-200">Address</span>
                <span className="lg:p3 text-right text-interface-white">
                  5900 Balcones Drive, Suite 100, Austin, TX 78731, USA
                </span>
              </div>
            </div>
            <div className="lg:p3 mt-6 justify-between space-y-6 text-center text-sm md:flex md:space-y-0 lg:mt-14 lg:text-left">
              <div>
                <div className="lg:p3-medium text-base text-coffee-400">
                  Company
                </div>
                <div className="mt-4 space-y-3 *:block">
                  <Link href="/" className="p3 text-interface-white">
                    Home
                  </Link>
                  <Link href="/about" className="p3 text-interface-white">
                    About us
                  </Link>
                  <Link href="/career" className="p3 text-interface-white">
                    Career
                  </Link>
                </div>
              </div>
              <div>
                <div className="lg:p3-medium text-base text-coffee-400">
                  Support
                </div>
                <div className="mt-4 space-y-3 *:block">
                  <Link href="/help" className="p3 text-interface-white">
                    Help Center
                  </Link>
                  <Link
                    href="/refund-policy"
                    className="p3 text-interface-white"
                  >
                    Cancellation Policies
                  </Link>
                </div>
              </div>
              <div>
                <div className="lg:p3-medium text-base text-coffee-400">
                  Resources
                </div>
                <div className="mt-4 space-y-3 *:block">
                  <Link href="/terms" className="p3 text-interface-white">
                    Terms of Use
                  </Link>
                  <Link
                    href="/privacy-policy"
                    className="p3 text-interface-white"
                  >
                    Privacy Policy
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="border-t border-coffee-500 bg-interface-black">
        <div className="m-auto flex max-w-screen-xl items-center justify-between px-4 py-4 lg:py-6 xl:px-0">
          <div className="lg:p4 text-xs text-interface-white">
            © Second Lab, LLC. All rights reserved
          </div>
          <Link
            href="https://www.linkedin.com/company/second-lab/"
            target="_blank"
          >
            <img
              src="/icons/linkedin.svg"
              alt="linkedin"
              className="lg:h-8 lg:w-8"
            />
          </Link>
        </div>
      </div>
    </div>
  );
}
