import { X } from "lucide-react";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { cn } from "@/lib/utils";

interface Props {
  children: React.ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
}

export function ModalDialog(props: Props) {
  return (
    <div
      className={cn(
        "invisible fixed left-0 top-0 z-20 h-screen w-screen bg-[#232D2A96]",
        props.open && "visible",
      )}
    >
      <main
        className={cn(
          "absolute left-0 right-0 top-10 m-auto w-fit rounded-lg bg-coffee-50",
        )}
      >
        <div className="flex items-center justify-between border-b border-b-coffee-200 p-6">
          <h6 className="font-medium text-emerald-black">{props.title}</h6>
          <button type="button" onClick={() => props.setOpen(false)}>
            <X size={24} />
          </button>
        </div>
        {props.children}
      </main>
    </div>
  );
}

export function DefaultDialog({
  title,
  children,
  onEsc,
  contentClassname,
}: {
  title: string;
  children: React.ReactNode;
  onEsc: () => void;
  contentClassname?: string;
}) {
  return (
    <Dialog defaultOpen onOpenChange={onEsc}>
      <DialogContent className={contentClassname}>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <DialogDescription>{children}</DialogDescription>
      </DialogContent>
    </Dialog>
  );
}
